import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "./List.module.css";

export default function DateOrderDetails(props) {
  const [dayDifference, setDayDifference] = useState(0);
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    if (props.details.id) {
      setDayDifference(
        moment().diff(props.details.orderDate, "days")
      );
    }
  }, [props.details]);

  const handleCopyText = (textToCopy) => {
    setShowCopied(true);
    navigator.clipboard.writeText(textToCopy);
    setTimeout(() => {
      setShowCopied(false);
    }, 1000);
  };

  return (
    <div className={`col-6 col-md-3`}>
      <div>
        <span className={styles.date}>{props.date} | </span>Days since order:{" "}
        <span
          style={{
            color: `${dayDifference >= 5 ? "red" : "#00ff4e"}`,
          }}>
          {dayDifference}
        </span>
      </div>
      <div style={{ color: "white", userSelect: "text", fontWeight: "bold" }}>
        <div
          className="ml-1 mr-1 pl-1 pr-1 mt-1"
          style={{
            display: "inline-block",
            backgroundColor: "#4867df",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={()=>handleCopyText(props.NumOrderId)}>
          ID#: {props.NumOrderId}{" "}
        </div>
        <div
          className="ml-1 mr-1 pl-1 pr-1 mt-1"
          style={{
            display: "inline-block",
            backgroundColor: "#9b5c8f",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={()=>handleCopyText(props.ReferenceNum)}>
          REF#: {props.ReferenceNum}
        </div>
        {showCopied ? <span className={styles.fadeOut}>Copied!</span> : null}
      </div>
    </div>
  );
}
