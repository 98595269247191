import React, { useState, useEffect, useContext } from "react";
import "../../App.css";
import styles from "./Dashboard.module.css";
import moment from "moment";
import { OrderContext } from "../../context/OrderContext";

export default function Time() {
  const { orders } = useContext(OrderContext);
  const [time, setTime] = useState("");

  useEffect(() => {
    dateTime();
    setInterval(() => {
      dateTime();
    }, 1000);
  }, []);

  const dateTime = () => {
    setTime(moment().format("HH:mm:ss | DD-MMM-YY"));
  };

  return (
    <div className={`componentBackgroundColor ${styles.header}`}>
      <div className="row componentColor">
        <div className="col-6">{time}</div>
        <div className="col-6">Open Orders: {orders.length}</div>
      </div>
    </div>
  );
}
