import React, { useEffect, useState, useContext } from "react";
import { BrandContext } from "../../context/BrandContext";

import Channel from "./Channel.jsx";

export default function Channels() {
  const [channels, setChannels] = useState([]);
  const { brands } = useContext(BrandContext);

  useEffect(() => {
    brands.forEach((brand) => {
      setChannels((channels) => [
        ...channels,
        <Channel key={brand.name} details={brand}>
          {brand.name}
        </Channel>,
      ]);
    });
  }, [brands]);

  return (
    <div className="container-fluid">
      <div className="row">{channels}</div>
    </div>
  );
}
