import React from 'react'
import Orders from "./Orders.jsx"

export default function List() {
    return (
        <div className="container-fluid">
            <Orders />
        </div>
    )
}
