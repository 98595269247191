import React from "react";
import Items from "./Items.jsx";
import Notes from "./Notes.jsx";
import Actions from "./Actions.jsx";

export default function DetailsArea(props) {
  return (
    <div className="col-12">
      <div className="row text-center mt-3 mb-3">
        <Items items={props.details.items} />
        <Notes notes={props.details.internalNotes} details={props.details}/>
        <Actions details={props.details} />
      </div>
    </div>
  );
}
