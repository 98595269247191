import React from "react";
import DetailBox from "../Styled/DetailBox.jsx";
import Header from "../Styled/Header.jsx";
import OrderNote from "./OrderNote.jsx";
import OutOfStock from "./OutOfStock.jsx";

export default function Actions(props) {
  return (
    <div className="col-12 col-md-4">
      <Header>Actions</Header>
      <DetailBox>
        <div className="row">
          <OrderNote details={props.details} />
          <OutOfStock details={props.details} />
        </div>
      </DetailBox>
    </div>
  );
}
