import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import axios from "axios";
import CanvasJSReact from "./canvasjs.react";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function Vouchers() {
  const [allVouchers, setAllVouchers] = useState(0);
  const [redeemed, setRedeemed] = useState(0);
  const [active, setActive] = useState(0);
  const [cancel, setCancel] = useState(0);
  const [expired, setExpired] = useState(0);
  const [total, setTotal] = useState(0);
  const [average, setAverage] = useState('');

  const voucherCost = 5;

  useEffect(() => {
    const getAllVouchers = async () => {
      let active = 0;
      let redeemed = 0;
      await axios
        .get("https://nitrous.rt-itservices.co.uk/vouchers/redeemed/week")
        .then((response) => {
          setRedeemed(response.data.length);
          redeemed = response.data.length;
        })
        .catch((e) => console.log(e));
      await axios
        .get("https://nitrous.rt-itservices.co.uk/vouchers/active/week")
        .then((response) => {
          setActive(response.data.length);
          active = response.data.length;
        })
        .catch((e) => console.log(e));
      // console.log(active + redeemed);
      setAllVouchers(active + redeemed);
    };
    getAllVouchers();

    const getExpiredVouchers = async () => {
      let total = 0;
      let active = 0;
      let redeemed = 0;
      let cancel = 0;
      await axios
        .get("https://nitrous.rt-itservices.co.uk/vouchers/all/week")
        .then((response) => {
          setTotal(response.data.length);
          total = response.data.length;
        })
        .catch((e) => console.log(e));
      await axios
        .get("https://nitrous.rt-itservices.co.uk/vouchers/redeemed/week")
        .then((response) => {
          setRedeemed(response.data.length);
          redeemed = response.data.length;
        })
        .catch((e) => console.log(e));
      await axios
        .get("https://nitrous.rt-itservices.co.uk/vouchers/active/week")
        .then((response) => {
          setActive(response.data.length);
          active = response.data.length;
        })
        .catch((e) => console.log(e));
      await axios
        .get("https://nitrous.rt-itservices.co.uk/vouchers/void/week")
        .then((response) => {
          setCancel(response.data.length);
          cancel = response.data.length;
        });
      // console.log(total - (active + redeemed + cancel));
      setExpired(total - (active + redeemed + cancel));
    };
    getExpiredVouchers();

    const getAverage = async () => {
     
      await axios
        .get("https://nitrous.rt-itservices.co.uk/voucherLogs/times")
        .then((response) => {
          setAverage(response.data)
        //  alert(response.data)
          }
        )
        .catch((e) => console.log(e));
    }
    getAverage()
  }, []);

  CanvasJS.addColorSet("brandShades", [
    //colorSet Array
    "#f3a6c4",
    "#5cb8b2",
    "#FA524E",
  ]);
  const options = {
    height: 879,
    exportEnabled: true,
    colorSet: "brandShades",
    animationEnabled: true,
    theme: "dark1",
    title: {
      text: "Redemption Rate (Last 7 days)",
    },
    backgroundColor: "#303030",
    subtitles: [
      {
        text: `Total - ${allVouchers} - £${(allVouchers * voucherCost).toFixed(
          2
        )} || Expired - ${expired}`,
        verticalAlign: "center",
        fontSize: 30,
        dockInsidePlotArea: true,
      },
    ],
    data: [
      {
        type: "doughnut",
        showInLegend: true,
        indexLabel: "{name}: {y}",
        yValueFormatString: "#,###'%'",
        dataPoints: [
          {
            label: "Redeemed",
            name: "Redeemed",
            y: (redeemed / allVouchers) * 100,
          },
          { label: "Active", name: "Active", y: (active / allVouchers) * 100 },
          {
            label: "Expired",
            name: "Expired",
            y: (expired / allVouchers) * 100,
          },
        ],
      },
    ],
  };

  return (
    <div className="container-fluid">
      <h1>Tubbees Vouchers redemption</h1>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div
              className={`col-12`}
              style={{ padding: "0px", height: "880px", marginBottom: "2%" }}
            >
              <CanvasJSChart options={options} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            <h1>Average time between bought and purchased voucher</h1>
            </div>
            <p style={{
              textAlign: "center",
              fontSize: "1.75em",
              border: "2px solid #FA524E",
              borderRadius: "10px",
              padding: "1%",
              marginTop: "1%",
              marginLeft: "42%",
            }}>Average = {average} days</p>
          </div>
          <div className="row">
            <div className="col-12"></div>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "#fff",
        }}
      >
        <iframe
          src="https://voucher-redeem.tubbees.co.uk/#/data"
          title="Tubbees vouchers"
          style={{
            height: "100vh",
            width: "100vw",
          }}
        ></iframe>
      </div>
      <div className="col-12" style={{ marginTop: "2%" }}>
          <Iframe
            url="https://map.tubbees.co.uk"
            width="100%"
            height="880px"
            id="myId"
            className="myClassname"
            display="block"
            position="relative"
          />
        </div>
    </div>
  );
}
