import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { DragSwitch } from "react-dragswitch";
import "react-dragswitch/dist/index.css";
import moment from "moment";
import styles from "./List.module.css"

import { OrderContext } from "../../context/OrderContext";

import Order from "./Order.jsx";

export default function Orders() {
  const { orders, setOrders } = useContext(OrderContext);

  const [orderList, setOrderList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [highlight, setHighlight] = useState(false);
  const [oos, setOos] = useState(false);
  const [refresh, setRefresh] = useState(
    JSON.parse(localStorage.getItem("Refresh")) || false
  );

  useEffect(() => {
    setOrderList([]);
    setOrders(orders.sort((a, b) => a.id - b.id)); // To show oldest first
    orders.forEach((order) => {
      setOrderList((orders) => [
        ...orders,
        <Order key={order.id} id={order.id} details={order} />,
      ]);
    });
    console.log(refresh);
    if (refresh) {
      setTimeout(() => {
        window.location.reload();
      }, 60000);
    }
  }, [orders, setOrders, refresh]);

  const handleOosToggle = () => {
    if (!oos && highlight) {
      handleHighlightToggle();
    }
    setOos(!oos);
    document.getElementById("inputSearch").focus();
  };

  const handleHighlightToggle = () => {
    if (oos && !highlight) {
      handleOosToggle();
    }
    setHighlight(!highlight);
    document.getElementById("inputSearch").focus();
  };

  const searchOrders = (e) => {
    e.preventDefault();
    setSearchValue("");

    if (highlight) {
      if (document.getElementById(searchValue)) {
        document
          .getElementById(searchValue)
          .querySelector("div").style.backgroundColor = "purple";
      } else if (document.getElementsByClassName(searchValue).length > 0) {
        document
          .getElementsByClassName(searchValue)[0]
          .querySelector("div").style.backgroundColor = "purple";
      } else {
        alert("No Results Found");
      }
    }

    if (oos) {
      if (document.getElementById(searchValue)) {
        document
          .getElementById(searchValue)
          .querySelector(".btn-success")
          .click();
        setTimeout(() => {
          document
            .getElementById(searchValue)
            .querySelector(".btn-warning")
            .click();
          setTimeout(() => {
            document
              .getElementById(searchValue)
              .querySelector(".btn-danger")
              .click();
            setTimeout(() => {
              document
                .getElementById(searchValue)
                .querySelector(".btn-success")
                .click();
            }, 50);
          }, 50);
        }, 50);
      } else if (document.getElementsByClassName(searchValue).length > 0) {
        document
          .getElementsByClassName(searchValue)[0]
          .querySelector(".btn-success")
          .click();
        setTimeout(() => {
          document
            .getElementsByClassName(searchValue)[0]
            .querySelector(".btn-warning")
            .click();
          setTimeout(() => {
            document
              .getElementsByClassName(searchValue)[0]
              .querySelector(".btn-danger")
              .click();
            setTimeout(() => {
              document
                .getElementsByClassName(searchValue)[0]
                .querySelector(".btn-success")
                .click();
            }, 50);
          }, 50);
        }, 50);
      } else {
        alert("No Results Found");
      }
    }

    if (!oos && !highlight) {
      axios
        .get(
          `https://nitrous.rt-itservices.co.uk/openorders/findone/${searchValue}`
        )
        .then((res) => {
          setOrderList([]);
          setOrders([res.data]);
        })
        .catch((e) => {
          if (e.response.status === 404) {
            alert("No Results Found");
          }
          console.log(e);
        });
    }
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleRefresh = () => {
    localStorage.setItem("Refresh", !refresh);
    setRefresh(!refresh);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      searchOrders(e);
    }
  };

  return (
    <div className="row pl-3 pr-3">
      <div
        className="mt-3 mb-3 p-2 col-12"
        style={{ backgroundColor: "#303030", borderRadius: "5px" }}>
        <div className="row">
          <div className="col-6 col-md-2 d-flex align-items-center" style={{marginLeft:"20px"}}>
            <input
              type="tel"
              id="inputSearch"
              style={{ width: "100%", borderRadius: "5px", padding: "5px" }}
              placeholder="Search"
              value={searchValue}
              onKeyDown={handleKeyDown}
              onChange={handleSearchValue}
            />
          </div>

          <div
            className="col-6 col-md-1 m-2"
            style={{
              background: "#26a0da",
              padding: "5px 18px",
              borderRadius: "5px",
            }}
            type="submit"
            onClick={searchOrders}>
            Search
          </div>
          <div className={`${styles.toggles} col-sm-9 align-items-center`}>
            <label className="ml-3 mr-3 mt-2">
              <span style={{ color: "white", fontSize: "15px" }}>
                <span style={{ marginRight: "10px" }}>
                  Mark as out of stock
                </span>
                <DragSwitch onChange={handleOosToggle} checked={oos} />
              </span>
            </label>
            <label className="ml-3 mr-3 mt-2">
              <span
                style={{
                  color: "white",
                  fontSize: "15px",
                  marginLeft: "10px",
                }}>
                <span style={{ marginRight: "10px" }}>Highlight Order</span>

                <DragSwitch
                  onChange={handleHighlightToggle}
                  checked={highlight}
                />
              </span>
            </label>
            <label className="ml-3 mr-3 mt-2">
              <span
                style={{
                  color: "white",
                  fontSize: "15px",
                  marginLeft: "10px",
                }}>
                <span style={{ marginRight: "10px" }}>
                  Auto Refresh. Last updated:{" "}
                  {moment().format("DD/MM HH:mm:ss")}
                </span>

                <DragSwitch onChange={handleRefresh} checked={refresh} />
              </span>
            </label>
          </div>
        </div>
      </div>

      {orderList}
    </div>
  );
}
