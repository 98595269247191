import React from 'react'
import styles from "./Styled.module.css"

export default function DetailBox(props) {
    return (
        <div className={styles.detailBox}>
            {props.children}
        </div>
    )
}
