import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styles from './refunds.module.css'

export default function Toolbar(props) {

    const [order, setOrder] = useState('')
    const [highCheck, setHighCheck] = useState(false)
    const [newCheck, setNewCheck] = useState(true)
    const [oldCheck, setOldCheck] = useState(false)
    const [v4uCheck, setV4UCheck] = useState(true)
    const [tubbeesCheck, setTubbeesCheck] = useState(true)
    const [raceTrackCheck, setRaceTrackCheck] = useState(true)
    const [reasons, setReasons] = useState([])

    useEffect(() => {
        axios.get('https://nitrous.rt-itservices.co.uk/refund/reasons').then((res) => {
            setReasons(res.data)
        });
        if (highCheck) {
            props.high();
        } else if (newCheck) {
            props.new();
        } else if (oldCheck) {
            props.old();
        }
    }, [highCheck, newCheck, oldCheck])

    const searchOrder = () => {
        axios.get(`https://nitrous.rt-itservices.co.uk/refund/search?id=${order}`).then((res) => {
            if (order === '') {
                props.reset()
            } else if (res.data == null) {
                props.notFound();
            } else {
                props.search(order)
            }
        })
    }

    const getKey = (event) => {
        if (event.key === "Enter") {
            searchOrder();
        }
    }

    const filterRefunds = (site) => {
        if (site === 'vaped4u') {
            props.filterR(site, !v4uCheck)
            setV4UCheck(!v4uCheck)
        } else if (site === "tubbees") {
            props.filterR(site, !tubbeesCheck)
            setTubbeesCheck(!tubbeesCheck)
        } else {
            props.filterR(site, !raceTrackCheck)
            setRaceTrackCheck(!raceTrackCheck)
        }
    }

    return (
        <div className="m-2 p-3" style={{ backgroundColor: "#3e3e3e" }}>
            <div className="row">
                <div className={`${styles.searchBox} col input-group`}>
                    <input type="text" className="form-control h-100" placeholder="Order #" onChange={e => setOrder(e.target.value)} autoFocus onKeyDown={getKey} />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary btn-success" type="button" onClick={searchOrder}>🔎</button>
                        <button className="btn btn-outline-secondary btn-info" type="button" onClick={() => props.reset()}>🔁</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={`${styles.filter} col`}>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitches1" checked={highCheck} onChange={() => { setHighCheck(!highCheck); setOldCheck(false); setNewCheck(false) }} />
                        <br />
                        <label className="custom-control-label" htmlFor="customSwitches1">Highest</label>
                    </div>
                </div>
                <div className={`${styles.filter} col`}>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitches2" checked={newCheck} onChange={() => { setNewCheck(!newCheck); setOldCheck(false); setHighCheck(false) }} />
                        <br />
                        <label className="custom-control-label" htmlFor="customSwitches2">Newest</label>
                    </div>
                </div>
                <div className={`${styles.filter} col`}>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitches3" checked={oldCheck} onChange={() => { setOldCheck(!oldCheck); setHighCheck(false); setNewCheck(false) }} />
                        <br />
                        <label className="custom-control-label" htmlFor="customSwitches3">Oldest</label>
                    </div>
                </div>
                <div className={`${styles.filter} col`}>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitches4" checked={v4uCheck} onChange={() => { filterRefunds("vaped4u"); }} />
                        <br />
                        <label className="custom-control-label" htmlFor="customSwitches4">V4U</label>
                    </div>
                </div>
                <div className={`${styles.filter} col`}>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitches5" checked={tubbeesCheck} onChange={() => { filterRefunds("tubbees"); }} />
                        <br />
                        <label className="custom-control-label" htmlFor="customSwitches5">Tubbees</label>
                    </div>
                </div>
                <div className={`${styles.filter} col`}>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitches6" checked={raceTrackCheck} onChange={() => { filterRefunds("racetrack"); }} />
                        <br />
                        <label className="custom-control-label" htmlFor="customSwitches6">RaceTrack</label>
                    </div>
                </div>
                <div className={`${styles.filter} col`}>
                    <label>Reason</label>
                    <select className="" onChange={e => props.searchReasons(e.target.value)}>Reasons
                        <option>- Select Reason -</option>
                        {reasons.map((reason, id) => {
                            return (
                                <option key={id} value={reason}>{reason}</option>
                            )
                        })}
                    </select>
                </div>
                <div className={`${styles.filter} col`}>
                    <label>Filter By Date</label>
                    <input type="date" onChange={e => { props.getDate(e.target.value) }} />
                </div>
            </div>
        </div>

    )
}
