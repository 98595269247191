import React,{useEffect,useState} from 'react'

export default function ToolBar(props) {

    const [v4uCheck, setV4UCheck] = useState(true)
    const [tubbeesCheck, setTubbeesCheck] = useState(true)
    const [raceTrackCheck, setRaceTrackCheck] = useState(true)

    useEffect(()=>{
        props.getCheck(v4uCheck,tubbeesCheck,raceTrackCheck)
    },[v4uCheck,tubbeesCheck,raceTrackCheck])

    const filterRefunds = (site) => {
        if (site === 'vaped4u') {
            setV4UCheck(!v4uCheck)
            setTubbeesCheck(true)
            setRaceTrackCheck(true)
        } else if (site === "tubbees") {
            setV4UCheck(true)
            setTubbeesCheck(!tubbeesCheck)
            setRaceTrackCheck(true)
        } else {
            setV4UCheck(true)
            setTubbeesCheck(true)
            setRaceTrackCheck(!raceTrackCheck)
        }
    }

    return (
        <div className="m-2 p-3 text-center" style={{ backgroundColor: "#3e3e3e" }}>
            <div className="row">
                <div className="col">
                    <h3><u>Refund Data</u></h3>
                </div>
                </div>
                <div className="col">
                    <div className="row row-cols-1 row-cols-md-3">
                        <div className="col">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitches1" checked={raceTrackCheck} onChange={() => { filterRefunds("racetrack"); }} />
                                <br />
                                <label className="custom-control-label" htmlFor="customSwitches1">RaceTrack</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitches2" checked={tubbeesCheck} onChange={() => { filterRefunds("tubbees"); }} />
                                <br />
                                <label className="custom-control-label" htmlFor="customSwitches2">Tubbees</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitches3" checked={v4uCheck} onChange={() => { filterRefunds("vaped4u"); }} />
                                <br />
                                <label className="custom-control-label" htmlFor="customSwitches3">Vaped4U</label>
                            </div>
                        </div>
                    </div>
                </div>
            
        </div>
    )
}
