import React,{useEffect, useState} from 'react'
import styles from './refunds.module.css'

function Qlinn(props) {

    const [showModal, setShowModal]=useState(props.show)

    useEffect(() => {
        setShowModal(props.show)
    }, [props.show])

    const handleCancel = () => props.close();

    return (
        <div className={`${styles.blur}`} style={{display: showModal ? 'block' : 'none' }}>
            <div className={`${styles.background} m-3`}>
                <div className={`${styles.content} m-1`}>
                    <button className="btn btn-info w-100" onClick={handleCancel}>Close</button>
                    <iframe src={`https://qlinn.rt-itservices.co.uk?order=${props.reference}`} title="Qlinn" className={`${styles.iframe}`}></iframe>
                </div>
            </div>
        </div>
    )
}

export default Qlinn
