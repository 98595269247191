import React, { useState, useContext, useEffect } from "react";

import { BrandContext } from "../../context/BrandContext";

import styles from "./List.module.css";
import "../../App.css";

import moment from "moment";

import ShippingInfo from "./ShippingInfo.jsx";
import Status from "./Status.jsx";
import DetailsArea from "./DetailsArea.jsx";
import Modal from "react-modal";
import Qlinn from "./Qlinn.jsx";
import DateOrderDetails from "./DateOrderDetails";
import NoImage from "../../images/noimage.png"

export default function Order(props) {
  const { brands } = useContext(BrandContext);
  const [brandImage, setBrandImage] = useState(NoImage);
  const [date, setDate] = useState();
  const [detailsArea, setDetailsArea] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    brands.forEach((brand) => {
      if (brand.url === props.details.storeData.website) {
        setBrandImage(brand.altImageUrl);
      }
      // if (brand.url === "https://vaped4u.com") {
      //   setBrandImage(brand.altImageUrl);
      // }
    });

    if (props.details.id) {
      setDate(
        moment(props.details.orderDate).format(
          "HH:mm - DD/MM/YY"
        )
      );
    }
  }, [props, brands]);

  const handleDetailsArea = () => {
    setDetailsArea(!detailsArea);
    if(!detailsArea){
      document.getElementById(`${props.details.id}`).querySelector("#details").innerText = "Hide Details"
    } else {
      document.getElementById(`${props.details.id}`).querySelector("#details").innerText = "Show Details"

    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div
      className={`col-12 ${props.details.orderNumber}`}
      style={{ userSelect: "none" }}
      id={props.details.id}>
      <div
        className={`row pt-2 pb-2 componentBackgroundColor ${styles.orderBox} mb-2`}>
        <div className={`col-12 col-md-2`}>
          <img
            src={brandImage}
            className={`${styles.brandImage}`}
            height="45"
            alt="Brand"
          />
        </div>
        <DateOrderDetails
          date={date}
          NumOrderId={props.details.id}
          ReferenceNum={props.details.orderNumber}
          details={props.details}
        />
        <ShippingInfo details={props.details} />
        <Status details={props.details} showNotes={handleDetailsArea} />
        <div className="col-6 col-md-1">
          <div onClick={toggleModal}>
            <div className="btn btn-info">See on Qlinn</div>
          </div>
        </div>
        <div className={`col-6 col-md-1`}>
          <div className="btn btn-success" id="details" onClick={handleDetailsArea}>
            Show Details
          </div>
        </div>
        {detailsArea ? <DetailsArea details={props.details} /> : null}
        <Modal isOpen={showModal} style={{ content: { padding: "0px" } }}>
          <Qlinn clicked={toggleModal} orderId={props.details.id} />
        </Modal>
      </div>
    </div>
  );
}
