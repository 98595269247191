import React, { useContext, useEffect, useState } from 'react'
import RefundItem from './RefundItem'
import Toolbar from './Toolbar'
import { RefundContext } from '../../../context/RefundContext'
import axios from 'axios'
import moment from 'moment'

function RefundList() {

    const [refundData, setRefundData] = useState([])
    const { refunds } = useContext(RefundContext)

    useEffect(() => {
        refunds.forEach((refund, id) => {
            setRefundData((refundData) => [
                ...refundData,
                <RefundItem key={id} total={refund.Total} channel={refund.Channel} reference={refund.Reference} date={refund.Date} items={refund.Items} reason={refund.Reason} />
            ]);
        });
    },[refunds]);

    const searchOrder = (order) => {
        var orderId = parseInt(order)
        const data = refunds.filter(refund => refund.OrderID === orderId);
        data.forEach((refund) => {
            setRefundData(() =>
                <RefundItem total={refund.Total} channel={refund.Channel} reference={refund.Reference} date={refund.Date} items={refund.Items} reason={refund.Reason} />
            );
        });
    }

    const noOrders = () => {
        setRefundData([])
        alert("No Result Found")
        reset();
    }
    const reset = () => {
        window.location.reload();
    }

    const high = () => {
        const highSort = refundData.sort(function (a, b) {
            return b.props.total - a.props.total;
        })
        setRefundData([])
        highSort.forEach((refund) => {
            setRefundData((refundData) => [
                ...refundData,
                <RefundItem total={refund.props.total} channel={refund.props.channel} reference={refund.props.reference} date={refund.props.date} items={refund.props.items} reason={refund.props.reason} />
            ]);
        });
    }

    const newest = () => {
        const newSort = refundData.sort(function (a, b) {
            var d1 = moment(a.props.date, "DD/MM/YYYY HH.mm").toDate();
            var d2 = moment(b.props.date, "DD/MM/YYYY HH.mm").toDate();
            return d2 - d1;
        })
        setRefundData([])
        newSort.forEach((refund) => {
            setRefundData((refundData) => [
                ...refundData,
                <RefundItem total={refund.props.total} channel={refund.props.channel} reference={refund.props.reference} date={refund.props.date} items={refund.props.items} reason={refund.props.reason} />
            ]);
        });
    }

    const old = () => {
        const oldSort = refundData.sort(function (a, b) {
            var d1 = moment(a.props.date, "DD/MM/YYYY HH.mm").toDate();
            var d2 = moment(b.props.date, "DD/MM/YYYY HH.mm").toDate();
            return d1 - d2;
        })
        setRefundData([]);
        oldSort.forEach((refund) => {
            setRefundData((refundData) => [
                ...refundData,
                <RefundItem total={refund.props.total} channel={refund.props.channel} reference={refund.props.reference} date={refund.props.date} items={refund.props.items} reason={refund.props.reason} />
            ]);
        });
    }

    const channelCheck = (site, status) => {
        const v4u = refundData.filter(refund => refund.props.channel !== "vaped4u");
        const tubbees = refundData.filter(refund => refund.props.channel !== "tubbees");
        const rt = refundData.filter(refund => !refund.props.channel.toLowerCase().includes('racetrack'));
        var channel;
        if (site === "vaped4u") {
            channel = v4u;
        } else if (site === "tubbees") {
            channel = tubbees;
        } else {
            channel = rt;
        }
        if (status) {
            const data = refunds.filter(refund => refund.Channel.toLowerCase().includes(site))
            data.forEach((refund) => {
                setRefundData((refundData) => [
                    ...refundData,
                    <RefundItem total={refund.Total} channel={refund.Channel} reference={refund.Reference} date={refund.Date} items={refund.Items} reason={refund.Reason} />
                ]
                );
            });
        } else {
            setRefundData([])
            channel.forEach((refund) => {
                setRefundData((refundData) => [
                    ...refundData,
                    <RefundItem total={refund.props.total} channel={refund.props.channel} reference={refund.props.reference} date={refund.props.date} items={refund.props.items} reason={refund.props.reason} />
                ]);
            });
        }
    }

    const getDate = (date) => {
        axios.get(`https://nitrous.rt-itservices.co.uk/refund/getDate?date=${date}`).then((res) => {
            setRefundData([])
            res.data.forEach((refund) => {
                setRefundData((refundData) => [
                    ...refundData,
                    <RefundItem total={refund.Total} channel={refund.Channel} reference={refund.Reference} date={refund.Date} items={refund.Items} reason={refund.Reason} />
                ]);
            });
        }).catch((err) => {
            console.log(err)
        })
    }

    const searchReasons = (reason2) => {
        console.log(reason2)
        setRefundData([])
        const data = refunds.filter(refund => refund.Reason === reason2)
        data.forEach((refund) => {
            console.log(refund)
            setRefundData((refundData) => [
                ...refundData,
                <RefundItem total={refund.Total} channel={refund.Channel} reference={refund.Reference} date={refund.Date} items={refund.Items} reason={refund.Reason} />
            ]
            );
        });
    }

    return (
        <div>
            <Toolbar searchReasons={searchReasons} filterR={channelCheck} getDate={getDate} search={searchOrder} notFound={noOrders} reset={reset} new={newest} old={old} high={high} />
            <hr />
            {refundData}
        </div>
    )
}

export default RefundList
