import React, {useState } from 'react'
import GraphPercentages from './GraphPercentages'
import GraphTotals from './GraphTotals'
import RefundChannel from './RefundChannel'
import ToolBar from './ToolBar'

function RefundGraph() {

    const [v4u, setV4U] = useState('')
    const [tubbees, setTubbees] = useState('')
    const [rt, setRT] = useState('')

    const getCheck = (v4u, tubbees, rt) => {
        setV4U(v4u)
        setTubbees(tubbees)
        setRT(rt)
    }

    return (
        <div>
            <ToolBar getCheck={getCheck} />
            <div className="m-3 row row-cols">
                <div className="col mb-2">
                    <RefundChannel v4u={v4u} tubbees={tubbees} racetrack={rt} />
                </div>
            </div>
            <div className="m-3 row row-cols-1 row-cols-md-3 row-cols-lg-3">
                <div className="col mb-2">
                    <GraphPercentages v4u={v4u} tubbees={tubbees} racetrack={rt} />
                </div>
                <div className="col mb-2">
                    <GraphTotals v4u={v4u} tubbees={tubbees} racetrack={rt} total={true} sales={false} />
                </div>
                <div className="col mb-2">
                    <GraphTotals v4u={v4u} tubbees={tubbees} racetrack={rt} total={false} sales={true} />
                </div>
            </div>
        </div>
    )
}

export default RefundGraph
