import React, { useState,useEffect } from "react";

import styles from "./List.module.css";

export default function ShippingInfo(props) {
  const [color, setColor] = useState("#006496")
  const [service,setService]= useState('')

  useEffect(()=>{
    if(props.details.carrierCode === null){
      setColor("#f00000")
    }
    var ship = props.details.requestedShippingService.toLowerCase();
    console.log(ship)
    if(ship.includes("pickup")){
      setService("Local Pickup")
    }else if(props.details.carrierCode === null){
      setService('Default')
    }else{
      setService(props.details.carrierCode)
    }
  },[props])

  return (
    <div className="col-6 col-md-3">
      <div className={styles.shipping}>
        <div className={styles.shippingWeight}>
          {(props.details.weight.value/1000).toFixed(2)} kg
        </div>
        <div className={styles.shippingService} style={{background:color}}>
          {/* {props.details.carrierCode} */}
          {/* {props.details.requestedShippingService} */}
          {service}
        </div>
      </div>
    </div>
  );
}
