import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CanvasJSReact from "./canvasjs.react";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function GraphTotals(props) {

    const [total, setTotal] = useState([])
    const [percentages, setPercentages] = useState([])

    useEffect(() => {
        var data = []
        const getTotals = async () => {
            await axios.get("https://nitrous.rt-itservices.co.uk/refund/graph/").then((res) => {
                var tData = res.data;
                if (props.v4u === false) {
                    tData = tData.filter(channel => channel.Channel !== "vaped4u");
                } else if (props.tubbees === false) {
                    tData = tData.filter(channel => channel.Channel !== "tubbees");
                } else if (props.racetrack === false) {
                    tData = tData.filter(channel => !channel.Channel.toLowerCase().includes("racetrack"));
                }
                for (let i = 0; i < tData.length; i++) {
                    var color;
                    if (tData[i].Channel === "tubbees") {
                        color = '#e0a9c2';
                    } else if (tData[i].Channel === "vaped4u") {
                        color = '#78c7cc';
                    } else if (tData[i].Channel === "racetrackwow") {
                        color = 'red';
                    } else if (tData[i].Channel === "RaceTrack") {
                        color = 'gold';
                    }
                    var total = 0;
                    for (let e = 0; e < tData[i].Points.length; e++) {
                        total += tData[i].Points[e].y;
                    }
                    data.push({ y: total, label: tData[i].Channel, color: color })
                }
                setTotal(data)
            }).catch((err) => {
                console.log(err)
            });
        }

        const getSales = async () => {
            await getTotals();
            axios.get("https://nitrous.rt-itservices.co.uk/refund/graph/sales").then((res) => {
                var percentageData = []
                var totalData = res.data;
                if (props.v4u === false) {
                    totalData = totalData.filter(channel => channel.Channel !== "vaped4u");
                } else if (props.tubbees === false) {
                    totalData = totalData.filter(channel => channel.Channel !== "tubbees");
                } else if (props.racetrack === false) {
                    totalData = totalData.filter(channel => !channel.Channel.toLowerCase().includes("racetrack"));
                }
                for (let i = 0; i < totalData.length; i++) {
                    data.forEach(element => {
                        var total = 0
                        var percentage = 0;
                        if (element.label === totalData[i].Channel) {
                            total = parseFloat(totalData[i].Sales) + parseFloat(element.y)
                            parseFloat(element.y)
                            percentage = element.y / total * 100;
                            parseFloat(percentage)
                            var color;
                            if (element.label === "tubbees") {
                                color = '#e0a9c2';
                            } else if (element.label === "vaped4u") {
                                color = '#78c7cc';
                            } else if (element.label === "racetrackwow") {
                                color = 'red';
                            } else if (element.label === "RaceTrack") {
                                color = 'gold';
                            }
                            percentageData.push({ y: percentage, label: element.label, color: color })
                        }
                    });
                }
                setPercentages(percentageData)
            }).catch((err) => {
                console.log(err)
            })
        }
        getSales()
    }, [props.v4u, props.tubbees, props.racetrack])

    const options = {
        animationEnabled: true,
        theme: "dark1",
        title: {
            text: "Refunds Per Channel"
        },
        axisX: {
            title: "Channel",
            reversed: true,
        },
        axisY: {
            title: "Total Refunded (£)",
            includeZero: true,
        },
        data: [{
            type: "bar",
            dataPoints: total
        }]
    }

    const percentageOptions = {
        animationEnabled: true,
        theme: "dark1",
        title: {
            text: "Percentage of Total Sales"
        },
        axisX: {
            title: "Channel",
        },
        axisY: {
            title: "% Refunded",
            includeZero: true,
            maximum: 100,
        },
        data: [{
            type: "bar",
            dataPoints: percentages
        }]
    }

    return (
        <div >
            {props.total ? <div>
                <CanvasJSChart options={options} />
            </div> : null}
            {props.sales ? <div>
                <CanvasJSChart options={percentageOptions} />
            </div> : null}
        </div>
    )
}
