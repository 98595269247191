import React, { useEffect, useState } from "react";
import DetailBox from "../Styled/DetailBox.jsx";
import Header from "../Styled/Header.jsx";

import styles from "./List.module.css";

export default function Items(props) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems([])
    props.items.forEach((item) => {
      setItems((items) => [
        ...items,
        <div className={styles.item} key={styles.item}>
          <span>
            ({item.quantity}) - {item.name} - [{item.sku}]
          </span>
        </div>,
      ]);
    });
  }, [props.items]);

  return (
    <div className="col-12 col-md-4">
      <Header>Items</Header>
      <DetailBox>{items}</DetailBox>
    </div>
  );
}
