import React, { useEffect, useContext, useState } from "react";

import { OrderContext } from "../../context/OrderContext";

import moment from "moment";
import styles from "./Dashboard.module.css";

export default function Channel(props) {
  const { orders } = useContext(OrderContext);
  const [openOrders, setOpenOrders] = useState(0);
  const [nextDays, setNextDays] = useState(0);
  const [localPickup, setLocalPickup] = useState(0);
  const [express, setExpress] = useState(0);
  const [trafficLight, setTrafficLight] = useState("green");
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    const now = moment(); // Time right now

    // Loop through all of the orders
    orders.forEach((order) => {
      // If the subsource matches the Brands url
      if (props.details.url === order.storeData.website) {
        setOpenOrders((openOrders) => openOrders + 1); // Increment open orders

          if(order.requestedShippingService){
            if (order.requestedShippingService.includes("Next")) {
              setNextDays((nextDays) => nextDays + 1);
            }else if (order.requestedShippingService.includes("Express")) {
              setExpress((express) => express + 1);
            }else if (order.requestedShippingService.includes("Local Pickup")) {
              setLocalPickup((localPickup) => localPickup + 1);
            }
          }

        // If the date the order was created is between 2 and 5 days, mark as warning
        if (
          now.diff(moment(order.orderDate), "days") > 2 &&
          now.diff(moment(order.orderDate), "days") < 5
        ) {
          setTrafficLight("yellow");

          // If the date ordered was 5 days or more ago, set to red
        } else if (
          now.diff(moment(order.orderDate), "days") >= 5
        ) {
          setTrafficLight("red");
        }
      }
    });

    // If the time now is within 60 minutes of 15:00(Rough cut off for courier collection)
    if (
      moment().diff(moment().hour(15).minute(0).second(0), "minutes") * -1 >
        0 &&
      moment().diff(moment().hour(15).minute(0).second(0), "minutes") * -1 < 60
    ) {
      setWarning(true);
    }
  }, [props, orders]);

  return (
    <div className={`col-xs-12 col-md-6 ${styles.channel} mt-3 mb-3 `}>
      {/* If within warning time window and there is either at least 1 express or next day order, change color */}
      <div
        className={`row h-100 align-items-center componentBackgroundColor ${
          styles.channelInner
        } ${warning && (nextDays > 0 || express > 0) ? styles.nextDay : null}`}>
        {/* Brand image here */}
        <div
          className={`col-4 h-100 ${styles.brandImage}`}
          style={{
            backgroundImage: `url(${props.details.imageUrl})`,
            borderRight: `12px solid ${trafficLight}`,
          }}></div>
        <div className="col-8">
          <div className="col-12 p-2">
            <div className={`row ${styles.box}`}>
              <div className="col-6">
                Open: <b>{openOrders}</b>
              </div>
              <div className="col-6">
                Next Day: <b>{nextDays}</b>
              </div>
            </div>
          </div>
          <div className={`row ${styles.expressLocal}`}>
            <div className="col-12 p-2">
              Express: <b>{express}</b> | Local: <b>{localPickup}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
