import React, { useEffect, useState } from "react";
import DetailBox from "../Styled/DetailBox.jsx";
import Header from "../Styled/Header.jsx";
import moment from "moment";

import styles from "./List.module.css";

export default function Notes(props) {
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    setNotes([]);
    var currentNotes;
    if(props.notes===""||props.notes===null){
      currentNotes = JSON.parse("[]")
    }else{
      currentNotes = JSON.parse(props.notes);
    }
    currentNotes.forEach((note,i) => {
      setNotes((notes) => [
        ...notes,
        <React.Fragment key={note.i}>
          <div
            className={`col-4 border-left border-bottom border-right p-2 ${styles.innerNote}`}>
            {moment(note.date).format("DD/MM/YY HH:mm")}
          </div>
          <div
            className={`col-8 border-bottom border-right p-2 ${styles.innerNote}`}>
            {note.message}
          </div>
        </React.Fragment>,
      ]);
    });

  //   props.notes.reverse().forEach((note) => {
  //     setNotes((notes) => [
  //       ...notes,
  //       <React.Fragment key={note.OrderNoteId}>
  //         <div
  //           className={`col-4 border-left border-bottom border-right p-2 ${styles.innerNote}`}>
  //           {moment(note.NoteDate).format("DD/MM/YY HH:mm")}
  //         </div>
  //         <div
  //           className={`col-8 border-bottom border-right p-2 ${styles.innerNote}`}>
  //           {note.Note}
  //         </div>
  //       </React.Fragment>,
  //     ]);
  //   });
  }, [props.notes]);

  return (
    <div className="col-12 col-md-4">
      <Header>Notes</Header>
      <DetailBox>
        <div className="row mt-2 mb-2 ml-1 mr-1">
          <div className={`col-4 border p-2 ${styles.note}`}>Created</div>
          <div
            className={`col-8 border-top border-right border-bottom p-2 ${styles.note}`}>
            Note
          </div>
          {notes}
        </div>
      </DetailBox>
    </div>
  );
}
