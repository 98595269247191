import React, { useContext } from "react";
import qs from "query-string";

import { OrderContext } from "../../context/OrderContext";

export default function Navbar() {
  const { orders } = useContext(OrderContext);

  let params = qs.parse(window.location.search);
  if (params.showNav === "false") {
    return <></>;
  } else {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark componentBackgroundColor"
      >
        <a className="navbar-brand" href="/">
          LinnDash - Open: {orders.length}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <a className="nav-item nav-link" href="/List">
              List
            </a>
            <a className="nav-item nav-link" href="/sales">
              Recent Sales
            </a>
            <a className="nav-item nav-link" href="/refundlist">
              Refunds list
            </a>
            <a className="nav-item nav-link" href="/refundgraph">
              Refunds Graph
            </a>
            <a className="nav-item nav-link" href="/vouchers">
              Vouchers
            </a>
            <a className="nav-item nav-link" href="/fraud">
              Fraud
            </a>
          </div>
        </div>
      </nav>
    );
  }
}
