import React, { useEffect, useState } from "react";

import Identifiers from "./IdentifiersNew.jsx";
import RefundStatus from "./RefundStatus"

import styles from "./List.module.css";

export default function Status(props) {
  const [printed, setPrinted] = useState('');
  const [fraud,setFraud] = useState('');
  const [support,setSupport] = useState('')

  useEffect(() => {
    if(props.details.tagIds){
      props.details.tagIds.forEach((tag) => {
        if (tag==6945) {
          setPrinted(<span role="img" aria-labelledby="invoice" title="Invoice">
          📰
        </span>);
        }
        if (tag==9216) {
          setFraud(<span role="img" aria-labelledby="fraud" title="Fraud">
          🕵️‍♂️
        </span>);
        }
        if (tag==14448) {
          setSupport(<span role="img" aria-labelledby="support ticket" title="Support Ticket">
          🎫
        </span>);
        }
      });
    }
  }, [props.details]);

  return (
    <div className={`col-12 col-md-2 ${styles.identifiers}`}>
      <div style={{ fontSize: "25px" }}>
      {printed}
      {fraud}
      {support}
        {/* {props.details.tagIds === 6945 ? (
          <span role="img" aria-labelledby="invoice" title="Invoice">
            📰
          </span>
        ) : null} */}
        {/* {props.details.GeneralInfo.LabelPrinted ? (
          <span
            role="img"
            aria-labelledby="shippingLabel"
            title="Shipping label printed">
            🔖
          </span>
        ) : null} */}
        {/* {props.details.GeneralInfo.HoldOrCancel ? (
          <span role="img" aria-labelledby="locked" title="Locked">
            🔒
          </span>
        ) : null} */}
        {props.details.requestedShippingService.toLowerCase().includes("pickup") ? (
          <span role="img" aria-labelledby="Local Pickup" title="Local Pickup">
            🛒
          </span>
        ) : null}

        {props.details.internalNotes ? (
          <span
            role="img"
            onClick={props.showNotes}
            aria-labelledby="notes"
            title="Notes">
            ✍
          </span>
        ) : null}

        {props.details.weight.value === 0 ? (
            <span role="img" aria-labelledby="No weight" title="No weight">
              🏋️‍♂️
            </span>
        ) : null}
        {/* {unlinked ? (
          <span role="img" aria-labelledby="unlinked" title="Unlinked">
            🧷
          </span>
        ) : null} */}

        {/* {props.details.GeneralInfo.Identifiers ? (
          <Identifiers details={props.details.GeneralInfo} />
        ) : null} */}
        <Identifiers details={props.details} />
        <RefundStatus id={props.details.id}/>
      </div>
    </div>
  );
}
