import React, { useEffect, useState } from "react";
import axios from "axios";

export default function RefundStatus(props) {
  const [isRefund, isRefundSet] = useState(false);
  const [title, titleSet] = useState("");

  useEffect(() => {
      isRefundSet(false)
    axios
      .get(`https://nitrous.rt-itservices.co.uk/orders/refunds/${props.id}`)
      .then((res) => {
        if (res.data.length > 0) {
          isRefundSet(true);
        }
        titleSet(res.data[res.data.length - 1]?.status);
      })
      .catch((e) => console.log(e));
  }, [props.id]);

  return isRefund ? (
    <span role="img" aria-labelledby="Refund" title={title}>
      💷
    </span>
  ) : null;
}
