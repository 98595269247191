import React, { useState } from "react";
import axios from "axios";

export default function OrderNote(props) {
  const [orderNote, setOrderNote] = useState("");

  const handleOrderNoteChange = (e) => {
    setOrderNote(e.target.value);
  };

  const handleNoteSubmit = async (e) => {
    console.log(orderNote);
    e.preventDefault();
    let note = {message:orderNote, orderNumber:props.details.orderNumber};
    await axios
      .post(`https://nitrous.rt-itservices.co.uk/shipstation/addnote`,note).then((res) => {
        console.log(res);
      }).catch((e) => {
        console.log(e);
      });

    setOrderNote("");
  };

  return (
    <div className="col-6 text-center p-3">
      <form>
        <div className="form-group">
          <label htmlFor="formControlTextarea">Enter order note</label>
          <textarea
            className="form-control"
            id="formControlTextarea"
            rows="5"
            value={orderNote}
            onChange={handleOrderNoteChange}></textarea>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleNoteSubmit}>
          Submit
        </button>
      </form>
    </div>
  );
}
