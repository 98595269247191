import React, { useState } from "react";
import axios from "axios";

export default function OutOfStock(props) {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleShowConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const getAuthToken = async () => {
    let token;
    await axios
      .get("https://nitrous.rt-itservices.co.uk/tokens/linnworks")
      .then((response) => {
        token = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
    return token;
  };

  const handleOutOfStock = async () => {
    let token = await getAuthToken();
    let outOfStockString = `{"OrderIds":["${props.details.OrderId}"],"Tag":"OUT_OF_STOCK"}`;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("request", outOfStockString);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://eu-ext.linnworks.net//api/OpenOrders/AssignOrderIdentifier",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    console.log("set to out of stock");
    setShowConfirm(!showConfirm);
  };

  return (
    <div className="col-6 text-center p-3">
      <div>Mark as Out of Stock</div>
      <div className="btn btn-warning mt-5 col-12 " onClick={handleShowConfirm}>
        Mark as OOS
      </div>
      {showConfirm ? (
        <div>
          <div className="mt-4">Are you sure?</div>
          <div
            className="btn btn-danger ml-2 mr-2 mt-3"
            onClick={handleOutOfStock}>
            Yes
          </div>
          <div
            className="btn btn-success ml-2 mr-2 mt-3"
            onClick={handleShowConfirm}>
            No
          </div>
        </div>
      ) : null}
    </div>
  );
}
