import React from "react";
import "../../App.css";

import Channels from "./Channels";
import Header from "./Header";

export default function Dashboard() {

  return (
    <div className="container-fluid">
      <Header />
      <Channels />
    </div>
  );
}
