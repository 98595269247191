import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CanvasJSReact from "./canvasjs.react";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function GraphPercentages(props) {

    const [percentages, setPercentages] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        var data = []
        var data2 = [];
        var totalRefunds = 0;
        axios.get("https://nitrous.rt-itservices.co.uk/refund/graph/").then((res) => {
            var percentageData = res.data;
                if(props.v4u===false){
                    percentageData = percentageData.filter(channel => channel.Channel !== "vaped4u");
                }else if(props.tubbees===false){
                    percentageData = percentageData.filter(channel => channel.Channel !== "tubbees");
                }else if(props.racetrack===false){
                    percentageData = percentageData.filter(channel => !channel.Channel.toLowerCase().includes("racetrack"));
                }
            for (let i = 0; i < percentageData.length; i++) {
                var channelTotal = 0
                for(let e=0;e<percentageData[i].Points.length;e++){
                    totalRefunds += percentageData[i].Points[e].y;
                    channelTotal += percentageData[i].Points[e].y;
                }
                data.push({ name: percentageData[i].Channel, y: channelTotal })
            }
            for (let c = 0; c < data.length; c++) {
                var color;
                if(data[c].name==="tubbees"){
                    color='#e0a9c2';
                }else if(data[c].name==="vaped4u"){
                    color='#78c7cc';
                }else if(data[c].name==="racetrackwow"){
                    color='red';
                }else if(data[c].name==="RaceTrack"){
                    color='gold';
                }
                var percentage = ((data[c].y / totalRefunds) * 100).toFixed(0);
                data2.push({ name: data[c].name, y: percentage ,color:color})
            }
            setPercentages(data2)
            setTotal(totalRefunds.toFixed(2))
        }).catch((err) => {
            console.log(err)
        })
    }, [props.v4u, props.tubbees, props.racetrack])

    const data = {
        animationEnabled: true,
        theme: "dark1",
        title: {
            text: "Percentage Refunds"
        },
        subtitles: [{
            text: `£${total}`,
            verticalAlign: "center",
            fontSize: 24,
            dockInsidePlotArea: true
        }],
        data: [{
            type: "doughnut",
            indexLabel: "{name}: {y}",
            yValueFormatString: "#,###'%'",
            dataPoints: percentages
        }]
    }

    return (
        <div>
            <CanvasJSChart options={data} />
        </div>
    )
}

export default GraphPercentages
