import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { OrderContext } from "./context/OrderContext";
import { BrandContext } from "./context/BrandContext";
import { RefundContext } from "./context/RefundContext";
import { useState, useEffect } from "react";
import axios from "axios";

import Navbar from "./components/Navbar/Navbar.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import List from "./components/List/List.jsx";
import SalesDashboard from "./components/SalesDashboard/SalesDashboard"
import Vouchers from "./components/Vouchers/Vouchers"
import RefundList from './components/Refund/List/RefundList'
import RefundGraph from './components/Refund/Graph/RefundGraph'
import Fraud from "./components/Fraud/Fraud";

function App() {
  const [orders, setOrders] = useState([]);
  const [brands, setBrands] = useState([]);
  const [refunds, setRefunds] = useState([]);

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/openorders/all")
      .then((response) =>{
        setOrders(response.data.sort((a, b) => b.id - a.id))}
      )
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("https://nitrous.rt-itservices.co.uk/brands")
      .then((response) => setBrands(response.data))
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("https://nitrous.rt-itservices.co.uk/refund")
      .then((response) => setRefunds(response.data))
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Router>
      <OrderContext.Provider value={{ orders, setOrders }}>
        <BrandContext.Provider value={{ brands, setBrands }}>
          <RefundContext.Provider value={{ refunds, setRefunds }}>
            <Navbar />
            <Switch>
              <Route exact path="/" >
                <Dashboard />
              </Route>
              <Route exact path="/list">
                <List />
              </Route>
              <Route exact path="/sales">
                <SalesDashboard />
              </Route>
              <Route exact path="/refundgraph" component={RefundGraph} />
              <Route exact path="/refundlist" component={RefundList} />
              <Route exact path="/vouchers" component={Vouchers} />
              <Route exact path="/fraud" component={Fraud} />
            </Switch>
          </RefundContext.Provider>
        </BrandContext.Provider>
      </OrderContext.Provider>
    </Router>
  );
}

export default App;
