import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CanvasJSReact from "./canvasjs.react";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function RefundChannel(props) {

    const [data, setData] = useState([])

    useEffect(() => {
        axios.get("https://nitrous.rt-itservices.co.uk/refund/graph/").then((res) => {
            setData([])
            var refundData = res.data;
            if(props.v4u===false){
                refundData = refundData.filter(channel => channel.Channel !== "vaped4u");
            }else if(props.tubbees===false){
                refundData = refundData.filter(channel => channel.Channel !== "tubbees");
            }else if(props.racetrack===false){
                refundData = refundData.filter(channel => !channel.Channel.toLowerCase().includes("racetrack"));
            }
                refundData.forEach(channel => {
                    setData((data) => [...data,
                    {
                        color: channel.Color,
                        type: "area",
                        name: channel.Channel,
                        showInLegend: true,
                        dataPoints: channel.Points
                    }
                    ]);
                });
        }).catch((err) => {
            console.log(err)
        })
    }, [props.v4u, props.tubbees, props.racetrack])

    const options = {
        theme: "dark1",
        title: {
            text: "Refunds Per Channel"
        },
        subtitles: [{
            text: "Last 30 Days"
        }],
        axisY: {
            prefix: "£",
            interval: 50,
        },
        axisX: {
            maximum: 30,
        },
        toolTip: {
            shared: true
        },
        data: data,
    }

    return (
        <div>
            <CanvasJSChart options={options} />
        </div>
    )
}
