import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './refunds.module.css';
import Qlinn from './Qlinn';

function RefundItem(props) {

    const [channelImg, setChannelImg] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [showItems, setShowItems] = useState(false)

    useEffect(() => {
        axios.get("https://nitrous.rt-itservices.co.uk/brands").then((response) => {
            for (let i in response.data) {
                if (response.data[i].name === props.channel) {
                    setChannelImg(response.data[i].altImageUrl)
                }
            }
        }).catch((e) => {
            console.log(e);
        });
    }, [props.channel])

    const openQlinn = () => setShowModal(true);
    const handleCancel = () => setShowModal(false);

    const showItemDetails = () => {
        setShowItems(!showItems)
    }

    return (
        <>
            {showModal?<Qlinn close={handleCancel} show={showModal} reference={props.reference}/>:null}
            <div className="row row-cols-1 row-cols-sm-5 row-cols-md-5 row-cols-lg-5 m-1" style={{ backgroundColor: "#3e3e3e", color: "white" }}>
                <img src={channelImg} className={`${styles.channelImg} col mt-2 mb-2`} alt={props.channel} />

                <div className="col">
                    <p className="row"><b>Refunded: </b>  {props.date}</p>
                    <p className="row"><b>Total: </b>  £{props.total}</p>
                </div>
                <p className="col"><b>Reason: </b>  {props.reason}</p>
                <button className="col btn btn-info h-100 mt-2" onClick={openQlinn}>#{props.reference} 🔗</button>
                <div className="col">
                    <button className="btn btn-success w-100 mt-2" onClick={showItemDetails}>Show Items</button>

                    <div style={{ display: showItems ? 'block' : 'none', border: '1px solid black', borderRadius:"5px",margin:"5px", backgroundColor: '#3e3e3e' }}>
                        {props.items.map(function (d,id) {
                            var price = parseFloat(d.subTotal);
                            price = price.toFixed(2)
                            return (
                                <div key={id}>{d.quantity} x {d.name} = £{price}
                                    <hr />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <br />
        </>
    )
}

export default RefundItem
