import React, { useEffect, useState } from "react";
import moment from "moment";

export default function Identifiers(props) {
  const [icons, setIcons] = useState([]);
  const [speed,setSpeed] = useState('')
  const [custom,setCustom]= useState('')

  useEffect(() => {
    if(props.details.requestedShippingService){
      if(props.details.requestedShippingService.includes("Next")){
        setIcons('⏰')
        setSpeed('Next day')
      }else if(props.details.requestedShippingService.includes("Express")){
        setIcons('⏱')
        setSpeed("Express")
      }
    }
    if(props.details.storeData.name === "CUSTOMHAMPERS"){
      setCustom('🧪')
    }
  }, [props.details,]);

  return (
    <>
    <span role="img" aria-labelledby="From Amazon" title="From Amazon" alt="From amazon">
      {/* If from Amazon display Amazon icon*/}
      {props.details.SubSource === "RaceTrack" ? (
        <img
          className="ml-1 mr-1"
          src="https://racetrackpitstop.co.uk/wp-content/uploads/2021/03/amazonIcon-1.png"
          height="25"
          width="25"
          alt="From Amazon"
        />
      ) : null}
    </span>
    <span role="img" aria-labelledby={speed} title={speed}>{icons}</span>
      <span role="img" aria-labelledby="Custom item" title="Custom item">{custom}</span>
</>
  );
}
