import React, { useEffect, useState } from "react";

export default function Qlinn(props) {
  const [link, setLink] = useState("");
  useEffect(() => {
    setLink(`https://qlinn.rt-itservices.co.uk?order=${props.orderId}`);
  }, [props.orderId]);

  return (
    <div style={{ color: "black" }}>
      <div
        onClick={props.clicked}
        style={{
          textAlign: "center",
          backgroundColor: "#65c5e1",
          fontWeight: "bold",
          cursor:"pointer"
        }}>
        CLOSE
      </div>
      <iframe src={link} height="828px" width="1821px" title="Qlinn"></iframe>
    </div>
  );
}
