import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CanvasJSReact from "../Refund/Graph/canvasjs.react";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function Fraud() {
    const [level1, setLevel1] = useState([])
    const [level2, setLevel2] = useState([])
    const [level3, setLevel3] = useState([])
    const [worldpay, setWorldpay] = useState([])
    const [weeklyData, setWeeklyData] = useState([])
    const [log, setLog] = useState(0)
    const [highestSpam, setHighestSpam] = useState('')
    useEffect(() => {
        axios.get("https://nitrous.rt-itservices.co.uk/spam/fraudLevels").then((res) => {
            setLevel1(res.data.fraud1.length)
            setLevel2(res.data.fraud2.length)
            setLevel3(res.data.fraud3.length)
        }).catch((err) => {
            console.log(err)
        })
        axios.get("https://nitrous.rt-itservices.co.uk/spam/highest").then((res) => {
            setHighestSpam(res.data)
        }).catch((err) => {
            console.log(err)
        })
        axios.get("https://nitrous.rt-itservices.co.uk/spam/log").then((res) => {
            setLog(res.data.length)
        }).catch((err) => {
            console.log(err)
        })
        axios.get("https://nitrous.rt-itservices.co.uk/spam/worldpay").then((res) => {
            setWorldpay(res.data)
        }).catch((err) => {
            console.log(err)
        })
        axios.get("https://nitrous.rt-itservices.co.uk/spam/graph").then((res) => {
            setWeeklyData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    CanvasJS.addColorSet("levelShades", [
        "green",
        "orange",
        "red"
    ]);

    var options = {
        animationEnabled: true,
        theme: "dark1",
        colorSet: "levelShades",
        title: {
            text: "Fraud Levels In Last 7 Days"
        },
        axisX: {
            title: "Day",
        },
        axisY: {
            title: "No. Of Orders",
        },
        legend: {
            cursor: "pointer",
            fontSize: 16,
        },
        toolTip: {
            shared: true
        },
        data: [{
            name: "Level 1",
            type: "spline",
            showInLegend: true,
            dataPoints: weeklyData.level1
        },
        {
            name: "Level 2",
            type: "spline",
            showInLegend: true,
            dataPoints: weeklyData.level2
        },
        {
            name: "Level 3",
            type: "spline",
            showInLegend: true,
            dataPoints: weeklyData.level3
        }]
    }

    var fraudLevels = {
        animationEnabled: true,
        theme: "dark1",
        title: {
            text: "Fraud Order Check - Last 7 Days"
        },
        axisX: {
            title: "Level",
        },
        axisY: {
            title: "No. Of Orders",
        },
        data: [{
            type: "column",
            dataPoints: [
                { label: "1", y: level1, color: "green", },
                { label: "2", y: level2, color: "orange", },
                { label: "3", y: level3, color: "red", },
            ]
        }],
    }

    var worldpayLog = {
        animationEnabled: true,
        theme: "dark1",
        title: {
            text: "WorldPay Refused Orders - Last 7 Days"
        },
        axisX: {
            title: "Days",
        },
        axisY: {
            title: "No. Of Transactions",
        },
        data: [{
            type: "column",
            dataPoints: worldpay
        }],
    }

    return (
        <div className="container-fluid">
            <div className="mt-2 row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2">
                <div className='col my-2'>
                    <div className='mx-3 row p-2 border border-white'>
                        <CanvasJSChart options={fraudLevels} />
                    </div>
                </div>
                <div className="col my-2">
                    <div className='mx-3 row p-2 border border-white'>
                        <CanvasJSChart options={options} />
                    </div>
                </div>
            </div>
            <div className="mt-2 row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2">
                <div className='col my-2'>
                    <div className='mx-3 row p-2 border border-white'>
                        <CanvasJSChart options={worldpayLog} />
                    </div>
                </div>
                <div className="col my-2">
                    <div className="mx-3 border border-white p-5 text-center">
                        <h1><u>Current Highest Spam Score</u></h1>
                        <h5>Order ID - #{highestSpam.orderId}</h5>
                        <h5>Spam Score - {highestSpam.spamScore}</h5>
                        <h5>Fraud Level - {highestSpam.fraudLevel}</h5>
                    </div>
                    <div className="mx-3 border border-white p-4 text-center">
                        <br/>
                        <h1><u>Orders Placed On-Hold In Last 7 Days</u></h1>
                        <h3>{log}</h3>(In Dev)
                    </div>
                </div>
            </div>
        </div>
    )
}
